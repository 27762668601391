import "./SiteHeader-style";

class SiteHeaderElement extends HTMLElement {
    connectedCallback() {
        window.addEventListener("scroll", this.scrollHandler, { passive: true });
    }

    disconnectedCallback() {
        window.removeEventListener("scroll", this.scrollHandler);
    }

    private scrollHandler = () => {
        const threshold = (80 - 62) / 2;
        
        if (
            document.body.scrollTop > threshold ||
            document.documentElement.scrollTop > threshold
        ) {
            document.body.classList.add("scrolled");
        } else {
            document.body.classList.remove("scrolled");
        }
    }
}

customElements.define("site-header", SiteHeaderElement);